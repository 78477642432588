import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Libraries
import _ from 'lodash'

// CSS
import './Blogs.scss'


const Blogs = () => {
  const {
    allWordpressPost: { edges: items },
  } = useStaticQuery(graphql`
    {
      allWordpressPost {
        edges {
          node {
            id: wordpress_id
            path
            title
            acf {
              image: featured_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 850) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            content
          }
        }
      }
    }
  `)


  return (
    <div className="blogs">
      <div className="row">
        {_.map(items, ({ node: { id, title, path, content, acf: { image } } }) => (
          <div className="col-lg-4 mt-5 mt-lg-0 mb-lg-4" key={id}>
            <div className="blog position-relative">
              <BackgroundImage
                className="blog-image"
                fluid={image.localFile.childImageSharp.fluid}
              />
              <div className="blog-image-shadow" />
              <div className="blog-title mt-4 mb-3 text-uppercase font-size-m  font-weight-l font-family-secondary color-text-secondary text-center">
                <Link className="color-text-secondary" to={path}>{title}</Link>
              </div>
              <div className="px-5 pb-5 position-relative">
                <div
                  className="blog-content mb-4"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                <div className="text-center mx-auto">
                  <Link
                    className="request-offer-right-button button-hover px-4 py-3 text-uppercase font-size-sm font-weight-l"
                    to={path}
                  >
                    Lees verder
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Blogs
